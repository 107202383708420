<div class="h-100 d-flex bgLogin">
    <div class="loginContainer">
      <div class="d-flex">
        <div
          style="margin-left: 45px"
          class="d-flex flex-column align-items-center pt-3"
        >
          <div style="margin-top: 48px">
            <img
              class="logo"
              [src]="'assets/images/logoStellantisBig.svg'"
              alt="logo"
            />
          </div>
          <div class="logoSeparator"></div>
          <div style="margin-top: 16px">
            <span class="rcLabelBold">RC </span>
            <span class="rcLabel">Portal</span>
          </div>
          <div
            class="d-flex flex-column align-items-center"
            style="margin: auto"
          >
            <div style="margin-right: auto; background-color: white">
              <!-- <div>
                <button
                  *ngIf="!redirecting"
                  class="btnLogin"
                  (click)="onLoginRequest()"
                >
                  <span class="loginBtnLabel"> Log in </span>
                </button>
              </div> -->
              <div *ngIf="redirecting" class="information">Redirecting to ADFS login page...</div>
            </div>
          </div>
        </div>
        <div class="imgContainer">
          <img [src]="'assets/images/loginImage.png'" alt="login image" />
        </div>
      </div>
    </div>
  </div>
  
